import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { green } from "@ant-design/colors";

const originalFetch = fetch;
fetch = async (...args) => {
  let [resource, options] = args;
  console.log("wwwss", localStorage.getItem("token"), args);
  const response = await originalFetch(resource, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: localStorage.getItem("token"),
    },
  });

  if (response.status === 401) {
    // Redirect to the login page
    localStorage.removeItem("token");
    window.location.reload();
    return;
  }


  return response;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: green.primary,
        colorLink: "#2c8544",
      },
    }}
  >
    <App />
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
